<template>
  <vx-card class="insert-internal-funds-transfer main-box md:w-1/2 top-zero-radius p-2">

    <div class="insert-internal-funds-transfer-fields">

      <div class="insert-internal-funds-transfer-field mt-3" v-if="checkUserPermissions('internal_fund.dynamicDate')">
        <custom-date-picker-input :label="$t('treasury.internalFundsTransfer.labels.date')"
                                  type="datetime"
                                  :input-format="$localeValidator('moment.dateTime')"
                                  :is-empty="true"
                                  :max="currentDate"
                                  :placeholder="$t('treasury.internalFundsTransfer.placeholder.date')"
                                  :invalid-text="$t('treasury.internalFundsTransfer.validators.dateHelp', {date: `${currentDate.split(' ')[1]} ${currentDate.split(' ')[0]}`})"
                                  :regex="dateTimeRegex"
                                  v-model="newDocument.date" />
      </div>
      <div class="insert-internal-funds-transfer-field mt-3">
<!--        <span class="label">{{ $t('treasury.internalFundsTransfer.labels.sourceType') }}</span>-->
        <custom-select :label="$t('treasury.internalFundsTransfer.labels.sourceType')"
                       :default="newDocument.sourceType"
                       :options="types"
                       @suggest:selected="resetSource"
                       v-model="newDocument.sourceType"/>
      </div>

      <div class="insert-internal-funds-transfer-field mt-3"
           v-if="newDocument.sourceType.value > 0">
        <span class="label">{{ $t('treasury.internalFundsTransfer.labels.source', {type: newDocument.sourceType.label}) }}</span>
        <custom-select :default="newDocument.source"
                       :options="newDocument.sourceType.value === 1 ? banks : cashes"
                       v-model="newDocument.source"/>
      </div>

      <div class="insert-internal-funds-transfer-field mt-3"
           v-if="newDocument.source.value.id > 0">
        <span class="label">{{ $t('treasury.internalFundsTransfer.labels.destinationType') }}</span>
        <custom-select :default="newDocument.destinationType"
                       :options="types"
                       @suggest:selected="resetDestination"
                       v-model="newDocument.destinationType"/>
      </div>

      <div class="insert-internal-funds-transfer-field mt-3"
           v-if="newDocument.destinationType.value > 0">
        <span class="label">{{ $t('treasury.internalFundsTransfer.labels.destination', {type: newDocument.destinationType.label}) }}</span>
        <custom-select :default="newDocument.destination"
                       :options="newDocument.destinationType.value === 1 ? banks : cashes"
                       v-model="newDocument.destination"/>
      </div>

      <template v-if="newDocument.sourceType.value === 1 && newDocument.destinationType.value === 1">
        <div class="insert-internal-funds-transfer-field mt-3">
          <span class="label">{{ $t('treasury.internalFundsTransfer.labels.paymentType') }}</span>
          <custom-select :default="newDocument.paymentType"
                         :options="paymentTypes"
                         @suggest:selected="handlePaymentTypeSelected"
                         v-model="newDocument.paymentType"/>
        </div>

        <div class="insert-internal-funds-transfer-field mt-3" v-if="['ctc', 'iban'].indexOf(newDocument.paymentType.value) > -1">
          <custom-price-input :label="$t('treasury.internalFundsTransfer.labels.bankWage')"
                              :classes="{'w-full': true}"
                              :is-empty="true"
                              v-model="newDocument.bankWage"/>
        </div>
      </template>

      <div class="insert-internal-funds-transfer-field mt-3"
           v-if="newDocument.sourceType.value > 0">
        <custom-price-input :label="newDocument.sourceType.value === 1 ? $t('treasury.internalFundsTransfer.labels.bankBalance') : $t('treasury.internalFundsTransfer.labels.cashBoxBalance')"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :disabled="true"
                            :value="newDocument.source.value.balance"/>
      </div>
    </div>

    <div class="insert-internal-funds-transfer-fields mt-3">
      <div class="insert-internal-funds-transfer-field">
        <custom-price-input :label="`${$t('treasury.internalFundsTransfer.labels.price')}`"
                            :classes="{'w-full': true}"
                            v-model="newDocument.price"
                            @pressEnter="insertDocument"/>
      </div>
    </div>

    <div class="insert-internal-funds-transfer-fields mt-3">
      <div class="insert-internal-funds-transfer-field">
        <custom-validate-textarea :label="`${$t('treasury.internalFundsTransfer.labels.description')}`"
                                  :classes="{'w-full': true}"
                                  :rtl="true"
                                  align="right"
                                  :is-empty="true"
                                  v-model="newDocument.description"
                                  @pressEnter="insertDocument"/>
      </div>
    </div>

    <vs-button id="insertDocumentBTN" @click="insertDocument" class="useral-action-button"/>
  </vx-card>
</template>

<script>
import moment from 'moment-jalaali'
import axios from 'axios'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import CustomPriceInput from '../../../../../components/customInput/customPriceInput'
import {insertInternalFundsTransfer} from '../../../../../http/requests/treasury/internalFund'
import CustomSelect from '../../../../../components/customSelect/customSelect'
import {getCashBoxes} from '../../../../../http/requests/treasury/cashBoxes'
import {getBanks} from '../../../../../http/requests/treasury/banks'
import CustomDatePickerInput from "../../../../../components/customInput/customDatePickerInput";
import {checkUserPermissions, getTimeFromServer} from "../../../../../assets/js/functions";
import CustomValidateTextarea from "@/components/customInput/customValidateTextarea.vue";
import {getTreasuryRequirements} from "@/http/requests/requirements";

export default {
  name: 'insertInternalFundsTransfer',
  metaInfo () {
    return {
      title: this.$t('treasury.internalFundsTransfer.insert.title')
    }
  },
  components: {
    CustomValidateTextarea,
    CustomDatePickerInput,
    CustomSelect, CustomPriceInput, CustomValidateInput, CustomIcon},
  data () {
    return {
      dateTimeRegex: this.$validator('regex.dateTime'),
      currentDate: '',
      types: [
        {
          label: this.$t('treasury.internalFundsTransfer.types.bank'),
          value: 1
        },
        {
          label: this.$t('treasury.internalFundsTransfer.types.cash'),
          value: 2
        }
      ],
      paymentTypes: [
        {
          label: this.$t('treasury.internalFundsTransfer.bankTypes.card'),
          value: 'ctc'
        },
        {
          label: this.$t('treasury.internalFundsTransfer.bankTypes.sheba'),
          value: 'iban'
        }
      ],
      cashes: [],
      banks: [],
      bankWages: {
        cardToCard: 0,
        iban: 0
      },
      newDocument: {
        date: {
          value: '',
          isValid: true
        },
        price: {},
        description: {
          value: '',
          isValid: true
        },
        source: {
          label: '-',
          value: 0
        },
        sourceType: {
          label: '-',
          value: 0
        },
        destination: {
          label: '-',
          value: 0
        },
        destinationType: {
          label: '-',
          value: 0
        },
        paymentType: {
          label: '-',
          value: 0
        },
        bankWage: {
          value: '',
          isValid: true
        }
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertDocumentBTN',
              color: 'success',
              icon: 'SAVE',
              iconPack: 'useral'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryInternalFundsTransfers'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 m-0 mx-auto')
    }, 50)
    this.currentDate = moment(getTimeFromServer()).format(this.$localeValidator('moment.dateTime'))

    this.getBanks()
    this.getCashes()
    this.getTreasuryRequirements()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getBanks () {
      getBanks().then(response => {
        const banks = response.data.data
        banks.forEach((bank) => {
          this.banks.push({
            label: bank.name,
            value: {
              id: bank.id,
              type: 'bank',
              balance: {
                value: bank.balance || '0',
                isValid: true
              }
            }
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    getCashes () {
      getCashBoxes().then(response => {
        const cashes = response.data.data
        cashes.forEach((cash) => {
          this.cashes.push({
            label: cash.name,
            value: {
              id: cash.id,
              type: 'cash',
              balance: {
                value: cash.balance || '0',
                isValid: true
              }
            }
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    resetSource () {
      let source = {}
      if (this.newDocument.sourceType.value === 1) {
        source = this.banks[0]
      } else {
        source = this.cashes[0]
      }
      this.newDocument.source = source || {
        label: '-',
        value: 0
      }
      this.newDocument.destinationType = {
        label: '-',
        value: 0
      }
    },
    resetDestination () {
      let destination = {}
      if (this.newDocument.destinationType.value === 1) {
        destination = this.banks[0]
      } else {
        destination = this.cashes[0]
      }
      this.newDocument.destination = destination || {
        label: '-',
        value: 0
      }
    },
    getTreasuryRequirements() {
      getTreasuryRequirements().then(response => {
        const app = response.data.data
        this.bankWages.cardToCard = app.card_to_card_wage || ''
        this.bankWages.iban = app.iban_wage || ''
      })
    },
    handlePaymentTypeSelected () {
      if (this.newDocument.paymentType.value === 'ctc') {
        this.newDocument.bankWage.value = this.bankWages.cardToCard
      } else if (this.newDocument.paymentType.value === 'iban') {
        this.newDocument.bankWage.value = this.bankWages.iban
      }
    },
    insertDocument () {
      const transfer = {
        price: (this.newDocument.price.value || 0).toString().replaceAll(',', ''),
        source: {},
        destination: {},
        description: this.newDocument.description.value
      }

      if (parseInt(transfer.price) === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.invalidPrice'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newDocument.sourceType.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.invalidSourceType'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newDocument.source.value.id === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.invalidSource'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newDocument.destinationType.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.invalidDestinationType'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newDocument.destination.value.id === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.invalidDestination'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (!this.newDocument.date.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.validators.date'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newDocument.sourceType.value === this.newDocument.destinationType.value && this.newDocument.source.value.id === this.newDocument.destination.value.id) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.equalSourceAndDestination'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      transfer.source[this.newDocument.sourceType.value === 1 ? 'bank_id' : 'cash_id'] = this.newDocument.source.value.id
      transfer.destination[this.newDocument.destinationType.value === 1 ? 'bank_id' : 'cash_id'] = this.newDocument.destination.value.id

      if (checkUserPermissions('internal_fund.dynamicDate') && this.newDocument.date.isValid) {
        transfer.date = this.newDocument.date.value
      }

      if (this.newDocument.sourceType.value === 1 && this.newDocument.destinationType.value === 1) {
        if (this.newDocument.paymentType.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.internalFundsTransfer.insert.notifications.invalidPaymentType'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        transfer.payment_type = this.newDocument.paymentType.value
        transfer.wage = this.newDocument.bankWage.value || this.newDocument.bankWage.placeholder || '0'
      }

      // if (moment(this.newDocument.date.value).isAfter(this.currentDate)) {
      //   this.$vs.notify({
      //     title: this.$t('alert.error.title'),
      //     text: this.$t('treasury.internalFundsTransfer.validators.future'),
      //     time: 2400,
      //     color: 'danger',
      //     icon: 'icon-alert-circle',
      //     iconPack: 'feather'
      //   })
      //   return false
      // }

      insertInternalFundsTransfer(transfer).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.internalFundsTransfer.insert.notifications.insert.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$router.push({name: 'treasuryInternalFundsTransfers'})
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
          case 406:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.internalFundsTransfer.insert.notifications.lowBalance'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break

          default:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.internalFundsTransfer.insert.notifications.insert.error'),
              time: 2400,
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break
          }
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.insert-internal-funds-transfer {

  .insert-internal-funds-transfer-fields {

    .insert-internal-funds-transfer-field {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 35px;
        top: 5px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 5px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      span.label {
        font-size: 12px;
        position: absolute;
        padding: 0 5px;
        left: 7px;
        top: -10px;
        z-index: 12000;
        //background: linear-gradient(180deg, #f8f8f8, #ffffff);
      }
    }

    &:first-child {
      background: #cecece22;
      border: 1px solid #cecece;
      border-radius: .5rem;
      padding: 0 10px;

      span.label, .custom-validate-input .input-label {
        //backdrop-filter: sepia(1);
      }
    }
  }
}
</style>
